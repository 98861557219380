<template>
  <div>
    <CSpinner style="display: block; position: fixed; top: 50%; left: 50%" v-if="loading === true" color="primary" />
    <CCard v-if="loading === false">
      <CCardBody>
        <div></div>
        <CRow>
          <CCol>
            <h3>
              <CIcon name="cil-file"></CIcon>
              Booking details
            </h3>
          </CCol>
          <CCol>
            <div style="float: right">
              <CRow>
                <CCol class="d-flex justify-content-end">
                  <CButton color="secondary" class="btn-lg" @click="goBack">
                    <CIcon name="cil-list" /> Back
                  </CButton>
                </CCol>
              </CRow>
            </div>
          </CCol>
        </CRow>

        <CAlert :color="alertType" v-if="message" fade>
          {{ message }}
        </CAlert>
        <hr />
        <br />
        <br />
        <div class="row">
          <div class="column">
            <CRow>
              <CCol col="auto" style="margin-top: -5vh !important">
                <h2>Customer</h2>
              </CCol>
            </CRow>
            <CRow style="display: block">
              <CCol col="auto">
                <CIcon name="cilHome" style="margin-bottom: 5px" class="text-primary"></CIcon>
                Billing Address:
              </CCol>
              <CCol style="max-width: 50% !important">
                <span>
                  {{ booking?.customer?.addresses[0].streetAddress1 }}
                </span>
                <span>
                  {{ booking?.customer?.addresses[0].postalCode }}
                </span>
                <span>
                  {{ booking?.customer?.addresses[0].city }}
                </span>
                <span>
                  {{ booking?.customer?.addresses[0].country }}
                </span>
              </CCol>
            </CRow>
            <CRow>
              <CCol col="5">
                <CIcon name="cilInbox" style="margin-bottom: 5px" class="text-primary"></CIcon>
                Email:
              </CCol>
              <CCol>
                <p>
                  {{ booking?.customer?.email }}
                </p>
              </CCol>
            </CRow>
            <CRow>
              <CCol col="5">
                <CIcon name="cil-user" style="margin-bottom: 5px" class="text-primary"></CIcon>
                Name:
              </CCol>
              <CCol>
                <CButton v-if="booking.customer?.id != emptyGuid" color="link"
                  @click="getCustomer(booking.customer?.id)" style="padding-left: 0; padding-top: 0">{{ fullName }}
                </CButton>
                <p v-if="booking.customer?.id === emptyGuid" style="padding-left: 0; padding-top: 0">
                  {{ fullName }}
                </p>
              </CCol>
            </CRow>
            <CRow>
              <CCol col="5">
                <CIcon name="cilPhone" style="margin-bottom: 5px" class="text-primary"></CIcon>
                Phone:
              </CCol>
              <CCol>
                <p>
                  {{ booking?.customer?.mobileNumber }}
                </p>
              </CCol>
            </CRow>
            <CRow>
              <CCol col="5">
                <CIcon name="cilFactory" style="margin-bottom: 5px" class="text-primary"></CIcon>
                Company:
              </CCol>
              <CCol>
                <p>
                  {{ booking?.customer?.company ?? "" }}
                </p>
              </CCol>
            </CRow>
            <CRow>
              <CCol col="5">
                <CIcon name="cilDollar" style="margin-bottom: 5px" class="text-primary"></CIcon>
                VATNumber:
              </CCol>
              <CCol>
                <p>
                  {{ booking?.customer?.vatNumber ?? "" }}
                </p>
              </CCol>
            </CRow>
            <CRow>
              <CCol col="5">
                <CIcon name="cilFlagAlt" style="margin-bottom: 5px" class="text-primary"></CIcon>
                Citizenship:
              </CCol>
              <CCol>
                <p>
                  {{ booking?.customer?.citizenship ?? "" }}
                </p>
              </CCol>
            </CRow>
          </div>
          <div class="column">
            <CRow>
              <CCol col="4">
                <CIcon name="cil-calendar" style="margin-bottom: 5px" class="text-primary"></CIcon>
                Booking No:
              </CCol>
              <CCol>
                <p>
                  {{ booking.bookingNumber }}
                </p>
              </CCol>
            </CRow>
            <CRow>
              <CCol col="4">
                <CIcon name="cil-user" style="margin-bottom: 5px" class="text-primary"></CIcon>
                Sold By:
              </CCol>
              <CCol>
                <p>
                  {{ booking?.soldBy }}
                </p>
              </CCol>
            </CRow>
            <CRow>
              <CCol col="4">
                <CIcon name="cil-clock" style="margin-bottom: 5px" class="text-primary"></CIcon>
                Booking Date:
              </CCol>
              <CCol>
                <p>
                  {{
                    moment(new Date(booking.createDate)).format($dateFormat)
                  }}
                </p>
              </CCol>
            </CRow>
            <CRow>
              <CCol col="4">
                <CIcon name="cilMoney" style="margin-bottom: 5px" class="text-primary"></CIcon>
                Currency:
              </CCol>
              <CCol>
                <p>
                  {{ booking.currencyCode ? booking.currencyCode : "" }}
                </p>
              </CCol>
            </CRow>
            <CRow>
              <CCol style="margin-bottom::5px!important" col="4">
                <CIcon name="cilMoney" style="margin-bottom: 5px" class="text-primary"></CIcon>
                Payment Method:
              </CCol>
              <CCol>
                <p>
                  {{ booking.paymentMethod ? booking.paymentMethod : "" }}
                </p>
              </CCol>
            </CRow>

            <CRow>
              <CCol col="4">
                <CIcon name="cil-check-circle" style="margin-bottom: 5px" class="text-primary"></CIcon>
                State:
              </CCol>
              <CCol>
                <CBadge v-if="booking.state < 3" :color="getBadge(booking.state)">
                  {{ booking.state == 2 ? "Paid" : "Unpaid" }}
                </CBadge>
                <CBadge v-if="booking.state == 6" color="success">
                  {{ booking.state == 6 ? "Partial Paid" : "" }}
                </CBadge>
                <CBadge v-if="booking.state == 3" color="danger">
                  Cancelled
                </CBadge>
                <CBadge v-if="booking.state == 4" color="danger">
                  Expired
                </CBadge>
                <CBadge v-if="booking.state == 5" color="light">
                  Confirmed
                </CBadge>
              </CCol>
            </CRow>
          </div>
          <div class="column">
            <CRow>
              <CCol col="5">
                <CIcon name="cilInfo" style="margin-bottom: 5px" class="text-primary"></CIcon>
                Marketing Consent:
              </CCol>
              <CCol>
                <p>
                  {{ booking?.customer?.consentToMarketing }}
                </p>
              </CCol>
            </CRow>
            <CRow>
              <CCol col="3">
                <CButton color="link" @click="openLogsModal()" style="padding-left: 0; padding-top: 0"><b><u>Show
                      booking
                      payment logs</u></b>
                </CButton>
              </CCol>
              <CCol col="3" v-if="booking.state == 6">
                <CButton color="link" @click="openRefundModal()" style="padding-top: 0"><b><u>Show refund logs
                      history</u></b>
                </CButton>
              </CCol>
            </CRow>
            <CRow>
              <CCol v-if="booking.state == 2 || booking.state == 6">
                <CButton color="danger" @click="makeDeleteModalVisible()">
                  <CIcon name="cilDelete" /> Refund
                </CButton>
              </CCol>
            </CRow>
            <CRow>
              <CCol v-if="booking.state == 5">
                <CButton color="danger" @click="makeDeleteModalVisible()">
                  <CIcon name="cilDelete" /> Cancel
                </CButton>
              </CCol>
            </CRow>

            <CRow style="margin-top: 15px !important">
              <CCol>
                <CButton color="success" @click="makeSendConfirmationEmailModalVisible()">
                  <CIcon name="cilEnvelopeLetter" /> Send Confirmation
                </CButton>
              </CCol>
            </CRow>
          </div>
        </div>

        <br />
        <br />
        <h3>
          <CIcon name="cil-list-rich"></CIcon>
          Booking items
        </h3>

        <CDataTable :items="items.filter((x) => x.shouldEnterInFinalPriceCalculation === true)
          " :fields="bookingItemsFields" hover v-if="booking.id != emptyGuid && items.length > 0"
          @row-clicked="rowClickHandler">
          <template #name="{ item }">
            <td>
              <h4>{{ item.name }}</h4>
            </td>
          </template>

          <template #from="{ item }">
            <td>
              <div class="row">
                <div class="col-auto">
                  <h4>
                    {{ formatDate(item.startDate) }}
                    {{ formatTime(item.startDate, item.endDate) }}
                  </h4>
                </div>
              </div>
            </td>
          </template>

          <template #to="{ item }">
            <td>
              <div class="row">
                <div class="col-auto">
                  <h4>
                    {{ formatDate(item.endDate) }}

                    <!-- Use a conditional rendering to display the time only if it's different from the start time -->
                    <span v-if="formatTime(item.endDate, item.startDate)">
                      {{ ' ' + formatTime(item.endDate, item.startDate) }}
                    </span>
                  </h4>

                </div>
              </div>

            </td>
          </template>

          <template #guestsInfo="{ item }">
            <td>
              <CButton color="link" @click.stop="getListOfGuestInfos(item)" style="padding-left: 0; padding-top: 0">
                <b><u>{{
                  item.guestsInfo != "undefined" ? item.guestsInfo : null
                }}</u></b>
              </CButton>
            </td>
          </template>

          <template #totalGuests="{ item }">
            <td>
              <h4>{{ item.totalGuests }}</h4>
            </td>
          </template>

          <template #quantity="{ item }">
            <td>
              <h4>{{ item.quantity }}</h4>
            </td>
          </template>

          <template #priceExcludingVAT="{ item }">
            <td>
              <h4>{{ item.priceExcludingVAT }}</h4>
            </td>
          </template>

          <template #totalVAT="{ item }">
            <td>
              <h4>{{ item.totalVAT }}</h4>
            </td>
          </template>

          <template #totalPrice="{ item }">
            <td>
              <h4>{{ item.totalPrice }}</h4>
            </td>
          </template>

          <template #currencyCode="{ item }">
            <td>
              <h4>{{ item.currencyCode }}</h4>
            </td>
          </template>

          <template #edit="{ item }">
            <td>
              <div class="row">
                <div class="col">
                  <CButton class="costom-button" style="background-color: transparent; box-shadow:none; color:black "
                    @click="openChangeTimeModal($event, item, 'startDate')" color="primary" type="button">
                    <CIcon name="cil-pencil" />
                  </CButton>
                </div>
              </div>
            </td>
          </template>

          <template #details="{ item }">
            <CCollapse :show="Boolean(item._toggled)" :duration="collapseDuration">
              <CCardBody>
                <div v-if="item.additionalInformation != null">
                  <h4 v-if="item.additionalInformation.length > 0">
                    Additional Information
                  </h4>
                  <ul v-if="item.additionalInformation.length > 0">
                    <li v-for="addInfo in item.additionalInformation">
                      <span>
                        <h5>{{ addInfo.type }}: {{ addInfo.details }}</h5>
                      </span>
                    </li>
                  </ul>
                </div>
                <div v-if="item.additionalInformation === null">
                  <h5>No additional information provided</h5>
                </div>

                <div style="margin-top: 15px">
                  <h5>Experience Rows</h5>
                </div>
                <CDataTable v-if="items.filter(
                  (x) =>
                    x.shouldEnterInFinalPriceCalculation === false &&
                    x.isFromInventory === false
                ).length != 0
                " :items="items.filter(
                  (x) =>
                    x.shouldEnterInFinalPriceCalculation === false &&
                    x.isFromInventory === false &&
                    x.baseBookingItemId === item.baseBookingItemId
                )
                  " :fields="bookingItemsFieldsLayer1" hover @row-clicked="rowClickHandler">
                  <template #name="{ item }">
                    <td>
                      <h6>{{ item.name }}</h6>
                    </td>
                  </template>

                  <template #from="{ item }">
                    <td>
                      <div class="row">
                        <div class="col-auto">
                          <h6>
                            {{ formatDate(item.startDate) }}

                            <!-- Use a conditional rendering to display the time only if it's different from the end time -->
                            <span v-if="formatTime(item.startDate, item.endDate)">
                              {{ ' ' + formatTime(item.startDate, item.endDate) }}
                            </span>
                          </h6>
                        </div>


                      </div>

                    </td>
                  </template>

                  <template #to="{ item }">
                    <td>
                      <div class="row">
                        <div class="col-auto">
                          <h6>
                            {{ formatDate(item.endDate) }}

                            <!-- Use a conditional rendering to display the time only if it's different from the end time -->
                            <span v-if="formatTime(item.startDate, item.endDate)">
                              {{ ' ' + formatTime(item.startDate, item.endDate) }}
                            </span>
                          </h6>
                        </div>
                      </div>
                    </td>
                  </template>

                  <template #guestsInfo="{ item }">
                    <td>
                      <CButton v-if="item.guestsInfo.toLowerCase().includes('adu')" color="link"
                        @click.stop="getListOfGuestInfos(item)" style="padding-left: 0; padding-top: 0"><u>{{
                          item.guestsInfo != "undefined"
                            ? item.guestsInfo
                            : null
                        }}</u>
                      </CButton>
                      <h6 v-if="!item.guestsInfo.toLowerCase().includes('adu')">
                        {{
                          item.guestsInfo != "undefined"
                            ? item.guestsInfo
                            : null
                        }}
                      </h6>
                    </td>
                  </template>

                  <template #quantity="{ item }">
                    <td>
                      <h6>{{ item.quantity }}</h6>
                    </td>
                  </template>

                  <template #priceExcludingVAT="{ item }">
                    <td>
                      <h6>{{ item.priceExcludingVAT }}</h6>
                    </td>
                  </template>

                  <template #totalVAT="{ item }">
                    <td>
                      <h6>{{ item.totalVAT }}</h6>
                    </td>
                  </template>

                  <template #totalPrice="{ item }">
                    <td>
                      <h6>{{ item.totalPrice }}</h6>
                    </td>
                  </template>

                  <template #currencyCode="{ item }">
                    <td>
                      <h6>{{ item.currencyCode }}</h6>
                    </td>
                  </template>

                  <template #edit="{ item }">
                    <td>
                      <div class="auto">

                        <CButton class="costom-button"
                          style="background-color: transparent; box-shadow:none; color:black "
                          @click="openChangeTimeModal($event, item, 'startDate')" color="primary" type="button">
                          <CIcon name="cil-pencil" />
                        </CButton>
                      </div>
                    </td>
                  </template>

                  <template #details="{ item }">
                    <CCollapse :show="Boolean(item._toggled)" :duration="collapseDuration">
                      <CCardBody>
                        <div style="margin-top: 15px">
                          <h5>Inventories</h5>
                        </div>
                        <CDataTable :items="items.filter(
                          (x) =>
                            x.shouldEnterInFinalPriceCalculation ===
                            false &&
                            x.isFromInventory === true &&
                            x.frontEndGroupingIdentifier ===
                            item.frontEndGroupingIdentifier
                        )
                          " :fields="bookingItemsFieldsLayer2" hover v-if="items.filter(
                            (x) =>
                              x.shouldEnterInFinalPriceCalculation ===
                              false && x.isFromInventory === true
                          ).length != 0
                          ">
                          <template #name="{ item }">
                            <td>
                              <div class="row">
                                <div class="col-auto">
                                  <small>{{ item.name }}</small>

                                </div>

                                <div class="col-auto">
                                  <CButton class="custom-button"
                                    style="background-color:transparent; box-shadow:none; color:black" color="primary"
                                    type="button" @click="openChangeInventoryItemModal($event, item)">
                                    <CIcon name="cil-pencil">

                                    </CIcon>
                                  </CButton>
                                </div>

                              </div>
                            </td>
                          </template>

                          <template #guestsInfo="{ item }">
                            <td>
                              <CButton v-if="item.guestsInfo.toLowerCase().includes('adu')
                              " color="link" @click.stop="getListOfGuestInfos(item)"
                                style="padding-left: 0; padding-top: 0"><u>{{
                                  item.guestsInfo != "undefined"
                                    ? item.guestsInfo
                                    : null
                                  }}</u>
                              </CButton>
                              <h6 v-if="!item.guestsInfo.toLowerCase().includes('adu')
                              ">
                                {{
                                  item.guestsInfo != "undefined"
                                    ? item.guestsInfo
                                    : null
                                }}
                              </h6>
                            </td>
                          </template>

                          <template #quantity="{ item }">
                            <td>
                              <small>{{ item.quantity }}</small>
                            </td>
                          </template>

                          <template #priceExcludingVAT="{ item }">
                            <td>
                              <small>{{ item.priceExcludingVAT }}</small>
                            </td>
                          </template>

                          <template #totalVAT="{ item }">
                            <td>
                              <small>{{ item.totalVAT }}</small>
                            </td>
                          </template>

                          <template #totalPrice="{ item }">
                            <td>
                              <small>{{ item.totalPrice }}</small>
                            </td>
                          </template>

                          <template #currencyCode="{ item }">
                            <td>
                              <small>{{ item.currencyCode }}</small>
                            </td>
                          </template>
                        </CDataTable>
                      </CCardBody>
                    </CCollapse>
                  </template>
                </CDataTable>
              </CCardBody>
            </CCollapse>
          </template>
        </CDataTable>
        <hr />

        <div id="pricingDetails" style="margin-right: 1.5vw; margin-top: 2vh">
          <CRow>
            <CCol col="9" style="float: left !important">
              <div id="changeStatus">
                <CRow>
                  <CCol col="4">
                    <CSelect label="Payment status" :value.sync="paymentStatus" :plain="true" :options="paymentStatuses"
                      placeholder="Select payment status">
                    </CSelect>
                  </CCol>
                </CRow>
                <CRow>
                  <CCol col="4">
                    <label for="commentTextArea">Comment</label>
                    <textarea id="commentTextArea" name="name" type="text" class="form-control" style="width: 100%"
                      v-model="comment" />
                  </CCol>
                </CRow>
                <CRow style="margin-top: 2rem">
                  <CCol sm="3">
                    <CInputRadio @change="calendarView = false" label="Notify customer" type="radio" name="types"
                      :checked.sync="notify" />
                  </CCol>
                  <CCol sm="2">
                    <CButton color="info" @click="sendandNotify()">
                      <CIcon name="cil-list" /> Change status
                    </CButton>
                  </CCol>
                </CRow>
              </div>
            </CCol>
            <CCol style="float: right !important; margin-top: 2%">
              <div class="row">
                <div class="column">
                  <h6>Total Excluding VAT:</h6>
                </div>
                <div class="column">
                  <CButton style="
                      line-height: 0.5;
                      min-width: 30%;
                      pointer-events: none;
                    " color="dark" size="lg" disabled>
                    {{ booking?.totalExcludingVAT }}
                    {{ booking?.currencyCode }}
                  </CButton>
                </div>
              </div>
              <div class="row" v-for="(value, key) in booking.vatSums">
                <div class="column">
                  <h6>VAT Percentage: {{ key }}%</h6>
                </div>
                <div class="column">
                  <CButton style="
                      line-height: 0.5;
                      min-width: 30%;
                      pointer-events: none;
                    " color="dark" size="lg" disabled>
                    {{ value }}
                    {{ booking?.currencyCode }}
                  </CButton>
                </div>
              </div>
              <div class="row">
                <div class="column">
                  <h6>Total VAT:</h6>
                </div>
                <div class="column">
                  <CButton style="
                      line-height: 0.5;
                      min-width: 30%;
                      pointer-events: none;
                    " color="dark" size="lg" disabled>{{ booking?.totalVAT }}
                    {{ booking?.currencyCode }}</CButton>
                </div>
              </div>
              <div class="row">
                <div class="column">
                  <h6>Total price:</h6>
                </div>
                <div class="column">
                  <CButton style="
                      line-height: 0.5;
                      min-width: 30%;
                      pointer-events: none;
                    " color="dark" size="lg" disabled>
                    <span>{{ booking?.totalPrice }}</span>
                    <span> {{ booking?.currencyCode }}</span>
                  </CButton>
                </div>
              </div>
              <CRow v-if="booking.discountValue != 0 && booking.discountValue != null" style="margin-bottom: 5px;">
                <CCol col="6">
                  <h6>Initial Price:</h6>
                </CCol>
                <CCol>
                  <CButton style="
                      line-height: 0.5;
                      min-width: 30%;
                      pointer-events: none;
                    " color="dark" size="lg" disabled>
                    <span>{{ booking?.initialPriceWithoutCoupon }}</span>
                    <span> {{ booking?.currencyCode }}</span>
                  </CButton>
                </CCol>
              </CRow>
              <CRow v-if="booking.discountValue != 0 && booking.discountValue != null" style="margin-bottom: 5px;">
                <CCol col="6">
                  <h6>Discount Value( {{ booking.bookingCouponCode }}):</h6>
                </CCol>
                <CCol>
                  <CButton style="
                      line-height: 0.5;
                      min-width: 30%;
                      pointer-events: none;
                    " color="dark" size="lg" disabled>
                    <span>{{ booking?.discountValue }} </span>
                    <span> {{ booking?.currencyCode }} </span>
                  </CButton>
                </CCol>
              </CRow>

              <CRow v-if="booking.discountValue != 0 && booking.discountValue != null">
                <CCol col="6">
                  <h6>Final Price:</h6>
                </CCol>
                <CCol>
                  <CButton style="
                      line-height: 0.5;
                      min-width: 30%;
                      pointer-events: none;
                    " color="dark" size="lg" disabled>
                    <span>{{ booking?.priceAfterCoupon }}</span>
                    <span> {{ booking?.currencyCode }}</span>
                  </CButton>
                </CCol>
              </CRow>
            </CCol>
          </CRow>
        </div>
        <div id="modal">
          <CModal title="Payment logs history" color="success" :show.sync="refundHistoryModal"
            @update:show="closeModal">
            <CRow>
              <CCol col="12">
                <ul>
                  <div v-for="log in booking?.paymentEntities">
                    <li v-if="log.isRefunded == true">{{ moment(new Date(log.createDate)).format($dateFormat) }} -
                      Refunded: {{
                        log.amount }}. Refund reason: {{ log.reason }}</li>
                    <li v-if="log.isRefunded == null">{{ moment(new Date(log.createDate)).format($dateFormat) }} -
                      Initial paid:
                      {{ log.amount }}.</li>
                    <li v-if="log.isRefunded == false">{{ moment(new Date(log.createDate)).format($dateFormat) }} -
                      Initial
                      paid: {{ log.amount }}.</li>

                  </div>
                </ul>
              </CCol>
            </CRow>
          </CModal>

          <CModal title="Payment logs history" color="success" :show.sync="logsModal" @update:show="closeModal">
            <CRow>
              <CCol col="12">
                <ul>
                  <div v-if="booking?.paymentTransactions?.includes('||')">
                    <div v-for="log in booking?.paymentTransactions?.split('.-').join('.||').split('||')">
                      <li>{{ log }}</li>
                    </div>
                  </div>
                  <div v-else>
                    <div v-for="log in booking?.paymentTransactions?.split('-')">
                      <li>{{ log }}</li>
                    </div>
                  </div>
                </ul>
                <div v-if="booking.state === 5">
                  <hr />
                  <!-- todo : custom text here -->
                  <b>Customer chose to pay later this booking so, Stayify should
                    get from partner
                    {{
                      booking.stayifyComissionForPaidLaterTransaction
                        .bookingAmount
                    }}
                    {{ booking.currencyCode }}.</b>
                </div>
              </CCol>
            </CRow>
          </CModal>

          <CModal title="Sending confirmation modal" color="success" :show.sync="sendConfirmationEmailModa"
            @update:show="sendConfirmationEmail">
            <CRow>
              <CCol col="12">
                <strong>

                  <CInput label="You are about to send a confirmation mail to" type="text"
                    v-model="confirmationEmail" />
                  Are you sure?
                </strong>
              </CCol>
            </CRow>
          </CModal>
          <CModal :title=getBookingName() color="danger" :show.sync="refundModal" @update:show="closeModal2">
            <CRow>
              <CCol>
                <div v-if="booking.state == 2 || booking.state == 6">
                  <strong>
                    Please fill in the above info in order to perform the refund
                  </strong>
                  <CInput label="Total Refund Amount" type="text" v-model="totalRefundAmount" />
                  <CInput label="Refund Reason" type="text" v-model="refundReason" />
                  <CRow style="margin-left:10px">
                    <CCol>
                      <input type="checkbox" class="form-check-input" v-model="unlockCalendarItems"
                        name="unlockCalendarItems" />
                      <label class="form-check-label" for="unlockCalendarItems">
                        Delete Calendar Locks
                      </label>
                    </CCol>
                  </CRow>
                </div>
              </CCol>
            </CRow>
          </CModal>

          <CModal title="Guests Infos" color="success" :show.sync="guestInfosModal" @update:show="closeModal3">
            <CRow>
              <CCol>
                <ul v-for="guest in guestInfoCorrespondingToItem">
                  <li v-for="info in guest.split(', ')">{{ info }}</li>
                </ul>
              </CCol>
            </CRow>
          </CModal>
        </div>
        <CModal title="Edit booking item" color="success" :show.sync="showChangeDate" @update:show="closeChangeDate">

          <CRow>
            <CCol>
              <CInput v-model="bindedChangeTime" label="Start time" type="datetime-local"></CInput>
            </CCol>
          </CRow>
          <CRow>
            <CCol>
              <CInput v-model="bindedChangeTimeEndDate" label="End time" type="datetime-local"></CInput>
            </CCol>
          </CRow>
        </CModal>

        <CModal title="Change inventory item" color="success" :show.sync="openChangeInventoryItem"
          @update:show="closeChangeInventoryItem">
          <CRow>
            <CCol>
              <CInput v-model="newInventoryItemStartDate" label="Select a new start date for you selected item"
                type="datetime-local"></CInput>
            </CCol>
          </CRow>
          <CRow>
            <CCol>
              <CInput v-model="newInventoryItemEndDate" label="Select a new end date for you selected item"
                type="datetime-local"></CInput>
            </CCol>
          </CRow>
          <CRow>
            <CCol>
              <CSelect label="Select an inventory" :options="interestInventories" :value.sync="newSelectedInventory"
                @change="sortItems()">
              </CSelect>
            </CCol>
          </CRow>
          <CRow>
            <CCol>
              <CSelect label="Change inventory item" :options="interestInventoryItemsFiltered"
                :value.sync="newSelectedInventoryItemId">
              </CSelect>

            </CCol>
          </CRow>
        </CModal>
      </CCardBody>
    </CCard>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "BookingsDetails",
  data: () => {
    return {
      emptyGuid: "00000000-0000-0000-0000-000000000000",
      logsModal: false,
      refundHistoryModal: false,
      sendConfirmationEmailModa: false,
      refundModal: false,
      totalRefundAmount: '',
      showChangeDate: false,
      openChangeInventoryItem: false,
      confirmationEmail: "",
      changeTimeFor: "",
      newSelectedInventoryItemId: "00000000-0000-0000-0000-000000000000",
      newSelectedInventory: "00000000-0000-0000-0000-000000000000",
      interestInventories: [],
      newInventoryItemStartDate: null,
      selectedInventoryItemToBeEdited: null,
      newInventoryItemEndDate: null,
      interestInventoryItemsUnmodified: [],
      interestInventoryItemsFiltered: [],
      refundReason: null,
      bindedChangeTime: null,
      bindedChangeTimeEndDate: null,
      item: null,
      unlockCalendarItems: true,
      loading: false,
      booking: {
        id: "00000000-0000-0000-0000-000000000000",
        bookingNumber: null,
        createDate: null,
        customer: null,
        wasDiscountRelative: false,
        bookingItems: [],
        paymentEntities: [],
        state: null,
        totalExcludingVAT: null,
        totalVAT: null,
        vatSums: null,
        totalPrice: null,
        currencyCode: null,
        paymentMethod: null,
        paymentTransactions: null,
        wasCouponApplied: false,
        initialPriceWithoutCoupon: 0,
        priceAfterCoupon: 0,
        bookingCouponCode: null,
        discountValue: 0,
        maxAllowedToBeRefunded: 0,
        stayifyComissionForPaidLaterTransaction: null,
      },
      guestTypes: [],
      guestInfos: [],
      guestInfoCorrespondingToItem: [],
      items: [],
      collapseDuration: 0,
      paymentStatuses: [],
      logsComponents: [],
      comment: "",
      paymentStatus: "",
      notify: false,
      guestInfosModal: false,
      bookingItemsFields: [
        "name",
        "from",
        "to",
        "guestsInfo",
        "totalGuests",
        "quantity",
        "priceExcludingVAT",
        "totalVAT",
        "totalPrice",
        "currencyCode",
        "edit"
      ],
      bookingItemsFieldsLayer1: [
        "name",
        "from",
        "to",
        "guestsInfo",
        "quantity",
        "priceExcludingVAT",
        "totalVAT",
        "totalPrice",
        "currencyCode",
        "edit"
      ],
      bookingItemsFieldsLayer2: [
        "name",
        "guestsInfo",
        "quantity",
        "priceExcludingVAT",
        "totalVAT",
        "totalPrice",
        "currencyCode",
      ],
      fullName: null,

      //Alert
      alertType: "danger",
      message: null,
    };
  },
  watch: {
    totalRefundAmount(newValue, oldValue) {
      // Automatically convert comma to dot for decimal numbers
      if (newValue !== oldValue) { // Additional check to prevent unnecessary replacements
        const formattedValue = newValue.replace(',', '.');
        if (formattedValue !== newValue) {
          this.totalRefundAmount = formattedValue;
        }
      }
    },
  },
  computed: {},
  methods: {
    formatDate(dateString) {
      const date = new Date(dateString);
      // Formats to 'dd.mm.yyyy', replacing '/' with '.'
      return this.moment.utc(new Date(date)).format(this.$dateFormat).split(" ")[0]
    },
    formatTime(dateString, compareWith) {
      const time = dateString.split("T")[1].split("+")[0];
      if (compareWith && time === compareWith.split("T")[1].split("+")[0]) {
        return ''; // No time if it's the same as the comparison
      }
      return this.moment.utc(new Date(dateString)).format(this.$dateFormat).split(" ")[1] // Return only the HH:mm part
    },

    closeChangeDate(status, evt, accept) {

      if (accept) {
        const date = new Date(this.bindedChangeTime);
        const endDate = new Date(this.bindedChangeTimeEndDate);
        debugger;
        this.item.startDate = this.toISOWithFixedOffset(date);
        this.item.endDate = this.toISOWithFixedOffset(endDate);
        this.changeTimeOfBooking();

      }

    },
    closeChangeInventoryItem(status, evt, accept) {

      if (accept) {
        this.changeCalendarItemData();
      }
      this.newSelectedInventoryItemId = "00000000-0000-0000-0000-000000000000";
    },
    changeCalendarItemData() {
      const startdate = new Date(this.newInventoryItemEndDate);
      const enddate = new Date(this.newInventoryItemStartDate);

      this.selectedInventoryItemToBeEdited.endDate = startdate.toISOString();
      this.selectedInventoryItemToBeEdited.startDate = enddate.toISOString();
      let self = this;
      axios
        .post(`${this.$apiAdress}/v1/Booking/change-booking-calendar-item/${self.booking.id}/${self.newSelectedInventoryItemId}`, self.selectedInventoryItemToBeEdited)
        .then((response) => {
          self.alertType = "success";
          setTimeout(function () {
            self.message = null;
          }, 5000);
          self.items = response.data;
          self.message = "Successfully updated datetime.";

        })
        .catch(function (error) {
          self.alertType = "danger";
          setTimeout(function () {
            self.message = null;
          }, 10000);
          self.message = error;
        });
    },
    changeTimeOfBooking() {
      let self = this;
      axios
        .post(`${this.$apiAdress}/v1/Booking/change-booking-items-dates/${self.booking.id}`, self.item)
        .then((response) => {
          self.alertType = "success";
          setTimeout(function () {
            self.message = null;
          }, 5000);
          self.bookingItems = [];
          self.items = [];
          self.get(self.booking.id);
          self.message = "Successfully updated datetime.";

        })
        .catch(function (error) {
          self.alertType = "danger";
          setTimeout(function () {
            self.message = null;
          }, 10000);
          self.message = error;
        });
    },
    sortItems(event) {
      let self = this;
      axios
        .get(this.$apiAdress + "/v1/ListItem/InventoryItemsFiltered/" + self.newSelectedInventory)
        .then(function (response) {
          self.interestInventoryItemsFiltered = response.data;
        })
        .catch(function (error) {
          self.alertType = "danger";
          setTimeout(function () {
            self.message = null;
          }, 10000);
          self.message = error;
        });
    },
    getBookingName() {
      if (this.booking.state === 2 || this.booking.state === 6) {
        return "Refund payment";
      } else {
        return "Cancel of the selected booking"
      }
    },
    makeDeleteModalVisible() {
      this.refundModal = true;
    },
    closeModal2(status, evt, accept) {
      if (accept) {
        this.cancelBooking();
        this.refundModal = false;
      } else {
        this.refundModal = false;
      }
    },
    closeModal(status, evt, accept) {
      this.logsModal = false;
      this.refundHistoryModal = false;
    },
    closeModal3(status, evt, accept) {
      this.guestInfoCorrespondingToItem = [];
      this.guestInfosModal = false;
    },
    goBack() {
      this.$router.back();
    },

    getBadge(status) {
      switch (status) {
        case 2:
          return "success";
        case 1:
          return "secondary";
        default:
          return "primary";
      }
    },
    sum(obj) {
      return Object.keys(obj).reduce(
        (sum, key) => sum + parseFloat(obj[key] || 0),
        0
      );
    },
    makeSendConfirmationEmailModalVisible() {
      this.sendConfirmationEmailModa = true;
    },
    sendConfirmationEmail(status, evt, accept) {
      if (accept) {
        let self = this;
        axios
          .get(
            `${this.$apiAdress}/v1/booking/TriggerConfirmationEmail/${self.$route.params.id}/${self.confirmationEmail}`
          )
          .then(function (response) {
            self.alertType = "success";
            setTimeout(function () {
              self.message = null;
            }, 5000);
            self.message = "Mail successfully triggered";
            self.confirmationEmail = self.booking?.customer?.email;
          })
          .catch(function (error) {
            self.alertType = "danger";
            setTimeout(function () {
              self.message = null;
            }, 10000);
            self.message = error;
          });
      }
    },
    getListOfGuestInfos(item) {
      let self = this;
      self.loading = true;

      axios
        .get(
          `${this.$apiAdress}/v1/booking/getGuestInfos/${self.$route.params.id}/${item.baseBookingItemId}`
        )
        .then(function (response) {
          self.guestInfoCorrespondingToItem = response.data;
          self.guestInfosModal = true;
          self.sendConfirmationEmailModa = false;
          self.loading = false;
        })
        .catch(function (error) {
          self.alertType = "danger";
          setTimeout(function () {
            self.message = null;
          }, 10000);
          self.message = error;
          self.sendConfirmationEmailModa = false;
        });
    },
    get(id) {
      let self = this;
      self.loading = true;
      axios
        .get(`${this.$apiAdress}/v1/booking/${id}`)
        .then(function (response) {
          if (!response?.data) {
            self.$router.push({ path: "/bookings" }); // if not getting data for this id, then go to list view
          } else {
            self.booking = response.data;
            self.totalRefundAmount = response.data.maxAllowedToBeRefunded;
            self.booking.bookingItems.map(function (value, key) {
              value.guests = value.guests;
              if (value.vatSums != null) {
                value.totalVAT = self.sum(value.vatSums);

                if (value.shouldEnterInFinalPriceCalculation == false) {
                  var num = Number(value.totalVAT / value.quantity); // The Number() only visualizes the type and is not needed
                  var roundedString = num.toFixed(2);
                  value.totalVAT = Number(roundedString);
                }
              }
              let guestNames = "";
              if (value.guestInfos != null) {
                debugger;
                let adultsNumbers = 0;
                let childNumber = 0;
                value.guests.map(function (valueGuest, key) {

                  let label = self.guestTypes.find(
                    (x) => x.value === valueGuest.guestTypeId
                  )?.label;
                  if (label.toLowerCase().trim() == "adults") {
                    adultsNumbers += value.totalGuests;
                  } else {
                    childNumber += valueGuest.totalGuests;
                  }
                  if (
                    value.askedForMainContact == true &&
                    label.toLowerCase().trim() == "adults"
                  ) {
                    value.guestsInfo =
                      valueGuest.totalGuests +
                      " " +
                      (valueGuest.totalGuests == 1
                        ? "Adult- "
                        : "Adults. " + "Main Contact is: ");
                  }
                });
                if (value.askedForMainContact == false) {
                  if (adultsNumbers != 0) {
                    value.guestsInfo =
                      adultsNumbers +
                      " " +
                      (adultsNumbers == 1 ? "Adult- " : "Adults- ");
                  } else {
                    value.guestsInfo = childNumber +
                      " " +
                      (childNumber == 1 ? "Child " : "Children");
                  }

                }
                if (value.guestInfos != null) {
                  value.guestInfos.map(function (valueGuest, key) {
                    guestNames +=
                      valueGuest.firstName + " " + valueGuest.lastName + ",";
                  });
                }

                value.guestsInfo += guestNames.substring(
                  0,
                  guestNames.length - 1
                );
              }
              value.guestsInfo = value.guestsInfo + "\n";
              if (value.guests != null) {
                value.guests.map(function (valueGuest, key) {
                  if (valueGuest.age != null) {
                    let label = self.guestTypes.find(
                      (x) => x.value === valueGuest.guestTypeId
                    )?.label;
                    if (
                      label.toLowerCase().trim() == "children" &&
                      valueGuest.quantity == 1
                    ) {
                      label = "child";
                    }
                    var guestInf = value.guestsInfo;
                    //length 10 means the length of undefined
                    if (
                      guestInf.length == 10 &&
                      value.shouldEnterInFinalPriceCalculation == false
                    ) {
                      value.guestsInfo =
                        valueGuest.quantity +
                        " " +
                        label.toLowerCase().trim() +
                        "-" +
                        valueGuest.age +
                        " years,";
                    } else if (
                      value.shouldEnterInFinalPriceCalculation == true
                    ) {
                      value.guestsInfo =
                        value.guestsInfo +
                        " " +
                        valueGuest.quantity +
                        " " +
                        label.toLowerCase().trim() +
                        "-" +
                        valueGuest.age +
                        " years,";
                    }
                  }
                });
              }
              value.wasCouponApplied = value.wasCouponApplied;
              value.initialPriceWithoutCoupon = value.initialPriceWithoutCoupon;
              value.priceAfterCoupon = value.priceAfterCoupon;
              value.discountValue = value.discountValue;
              value.wasDiscountRelative = value.wasDiscountRelative;
              value.totalGuests = value.totalGuests;
              value.bookingCouponCode = value.bookingCouponCode;
              value.additionalInformation = value.additionalInformation;
              value.guestsInfo = value.guestsInfo.substring(
                0,
                value.guestsInfo.length - 1
              );
              if (value.additionalInformation === null) {
                value._toggled = false;
              } else {
                value._toggled = true;
              }

              value.shouldEnterInFinalPriceCalculation =
                value.shouldEnterInFinalPriceCalculation;
              value.isFromInventory = value.isFromInventory;
              value.frontEndGroupingIdentifier =
                value.frontEndGroupingIdentifier;
              value.paymentMethod = value.paymentMethod;
              value.stayifyComissionForPaidLaterTransaction =
                value.stayifyComissionForPaidLaterTransaction;
              self.items.push(value);
            });


            self.fullName =
              self.booking.customer.firstName +
              " " +
              self.booking.customer.lastName;
          }
          self.paymentStatus = self.booking.state.toString();
          self.confirmationEmail = self.booking?.customer?.email

          self.loading = false;
        })
        .catch(function (error) {
          self.alertType = "danger";
          setTimeout(function () {
            self.message = null;
          }, 10000);
          self.message = error;
        });
    },
    rowClickHandler(item, index, columnname, event) {
      if (!event) event = window.event;
      event.cancelBubble = true;
      if (event.stopPropagation) event.stopPropagation();

      this.$set(
        this.items.find((x) => x.id === item.id),
        "_toggled",
        !item._toggled
      );
      this.collapseDuration = 300;
      this.$nextTick(() => {
        this.collapseDuration = 0;
      });
    },
    getGuestTypes() {
      let self = this;
      axios
        .get(`${this.$apiAdress}/v1/GuestType/GuestTypesList`)
        .then(function (response) {
          self.guestTypes = response.data;
        })
        .catch(function (error) {
          self.alertType = "danger";
          setTimeout(function () {
            self.message = null;
          }, 10000);
          self.message = error;
        });
    },
    cancelBooking() {
      let self = this;
      let form = new FormData();
      form.append("id", self.$route.params.id);
      form.append("Amount", self.totalRefundAmount);
      form.append("Reason", self.refundReason);
      form.append("UnlockCalendarItems", self.unlockCalendarItems);

      axios
        .post(this.$apiAdress + "/v1/Booking/CancelBooking", form)
        .then((response) => {
          self.alertType = "success";
          setTimeout(function () {
            self.message = null;
          }, 5000);
          self.message = "Successfully cancelled booking.";
          self.get(this.$route.params.id);

        })
        .catch(function (error) {
          self.alertType = "danger";
          setTimeout(function () {
            self.message = null;
          }, 10000);
          self.message = error.response.data.errors;
        });
    },
    sendandNotify() {
      let self = this;
      let form = new FormData();
      form.append("id", self.$route.params.id);
      form.append("paymentStatus", self.paymentStatus);
      axios
        .post(this.$apiAdress + "/v1/Booking/UpdateState", form)
        .then((response) => {
          self.alertType = "success";
          setTimeout(function () {
            self.message = null;
          }, 5000);
          self.message = "Successfully updated booking.";
        })
        .catch(function (error) {
          self.alertType = "danger";
          setTimeout(function () {
            self.message = null;
          }, 10000);
          self.message = error.response.data.errors;
        });
    },
    getCustomer(id) {
      const link = `/customers/create/${id.toString()}`;
      this.$router.push({ path: link });
    },
    openLogsModal() {
      this.logsModal = true;
    },
    openRefundModal() {
      this.refundHistoryModal = true;
    },
    openChangeTimeModal(event, item, operation) {
      if (!event) event = window.event;
      event.cancelBubble = true;
      if (event.stopPropagation) event.stopPropagation();

      console.log("hello vlad");
      // Assuming item.startDate is something like "2024-03-04T09:00:00+00:00"
      console.log(item.startDate);

      this.showChangeDate = true;
      this.item = item;
      this.changeTimeFor = operation;

      // Convert start date to UTC and format
      let dateObj = new Date(item.startDate);
      let convertedDateStart = dateObj.toISOString().slice(0, 19); // Converts to YYYY-MM-DDTHH:MM:SS format in UTC

      console.log("concatenanted is");
      console.log(convertedDateStart); // Logs the ISO string in UTC
      this.bindedChangeTime = convertedDateStart;

      // Convert end date to UTC and format
      let dateObjE = new Date(item.endDate);
      let convertedDateEnd = dateObjE.toISOString().slice(0, 19); // Also in UTC

      this.bindedChangeTimeEndDate = convertedDateEnd;
    },
    toISOWithFixedOffset(date) {
      var pad = function (num) {
        var norm = Math.floor(Math.abs(num));
        return (norm < 10 ? '0' : '') + norm;
      };

      // Format the date to ISO 8601 without timezone information
      var isoDate = date.getFullYear() +
        '-' + pad(date.getMonth() + 1) +
        '-' + pad(date.getDate()) +
        'T' + pad(date.getHours()) +
        ':' + pad(date.getMinutes()) +
        ':' + pad(date.getSeconds());

      // Append a fixed timezone offset of +00:00
      var fixedOffset = '+00:00';

      return isoDate + fixedOffset;
    },
    openChangeInventoryItemModal(event, item) {
      if (!event) event = window.event;
      event.cancelBubble = true;
      if (event.stopPropagation) event.stopPropagation();

      let dateObj = new Date(item.startDate);
      let year = dateObj.getFullYear();
      let month = String(dateObj.getMonth() + 1).padStart(2, '0');
      let day = String(dateObj.getDate()).padStart(2, '0');

      let convertedDateStart = `${year}-${month}-${day}`;
      dateObj = new Date(item.endDate);
      year = dateObj.getFullYear();
      month = String(dateObj.getMonth() + 1).padStart(2, '0');
      day = String(dateObj.getDate()).padStart(2, '0');

      let convertedDateEnd = `${year}-${month}-${day}`;

      this.newInventoryItemStartDate = convertedDateStart;
      this.newInventoryItemEndDate = convertedDateEnd;
      this.selectedInventoryItemToBeEdited = item;
      debugger;
      let index = this.interestInventoryItemsFiltered.findIndex(i => i.label == item.name)
      this.newSelectedInventoryItemId = this.interestInventoryItemsFiltered[index].value;
      this.openChangeInventoryItem = true;

    },
    populateStatuses() {
      let self = this;
      axios
        .get(this.$apiAdress + "/v1/ListItem/PaymentStatusList")
        .then(function (response) {
          self.paymentStatuses = response.data;
        })
        .catch(function (error) {
          self.alertType = "danger";
          setTimeout(function () {
            self.message = null;
          }, 10000);
          self.message = error;
        });
    },

    getInventories() {
      let self = this;
      axios
        .get(this.$apiAdress + "/v1/ListItem/InventoryList")
        .then(function (response) {
          self.interestInventories = response.data;
        })
        .catch(function (error) {
          self.alertType = "danger";
          setTimeout(function () {
            self.message = null;
          }, 10000);
          self.message = error;
        });

      axios
        .get(this.$apiAdress + "/v1/ListItem/GetAllInventoryItems")
        .then(function (response) {
          self.interestInventoryItemsUnmodified = response.data;
          self.interestInventoryItemsFiltered = response.data;
        })
        .catch(function (error) {
          self.alertType = "danger";
          setTimeout(function () {
            self.message = null;
          }, 10000);
          self.message = error;
        });
    }
  },

  mounted: function () {
    this.getGuestTypes();
    if (this.$route.params.id != this.emptyGuid) {
      this.get(this.$route.params.id);
      this.getInventories();
    }
    this.populateStatuses();
  },
};
</script>

<style scoped>
.card-body>>>table>tbody>tr>td {
  cursor: pointer;
}
</style>

<style type="text/css">
td {
  /* css-3 */
  white-space: -o-pre-wrap;
  word-wrap: break-word;
  white-space: pre-wrap;
  white-space: -moz-pre-wrap;
  white-space: -pre-wrap;
}

table>thead {
  background-color: #e8e6e6;
}

/* Create two equal columns that floats next to each other */
.column {
  float: left;
  width: 50%;
  padding: 10px;
}

/* Clear floats after the columns */
.row:after {
  content: "";
  display: table;
  clear: both;
}

/* Responsive layout - makes the two columns stack on top of each other instead of next to each other */
@media screen and (max-width: 600px) {
  .column {
    width: 100%;
  }
}

@media (hover: hover),
(-ms-high-contrast: none) {
  .table-hover tbody tr:hover {
    color: #3c4b64;
    background-color: transparent;
  }
}
</style>
